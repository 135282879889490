import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import heroImage from "../images/hero-image.svg"
import posScreenShot from "../images/smartpay_21-pos.png"
import mobileScreenShot from "../images/smartpay_21-mobile.jpeg"
import retailImage from "../images/smartpay_21-retail.svg"
import curbsideImage from "../images/smartpay_21-curbside.svg"
import motoImage from "../images/smartpay_21-moto.svg"
import fieldServiceImage from "../images/smartpay_21-field_service.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Solutions" />
    <main className="flex flex-col md:flex-row md:items-center max-w-7xl mx-auto mt-10">
      <div className="md:w-3/5 md:px-5 lg:px-10">
        <h1
          className="text-4xl tracking-tight font-light text-gray-900 text-center md:text-left mt-4 md:mt-0 lg:text-5xl">
          <span>Modern and secure</span>
          <br />
          <span className="text-purple-700"> contactless payments.</span>
        </h1>
        <p
          className="mt-3 max-w-md mx-auto text-lg text-gray-500 text-center md:text-left lg:text-xl md:mt-5 md:max-w-3xl font-light">
          Add true contactless payments to your POS solutions quickly and easily.
          <span className="whitespace-nowrap"> SmartPay 21</span> is the best solution for mobile wallet support without
          a terminal.
        </p>
        <div className="mx-4 md:mx-0 mt-10 sm:flex sm:justify-center md:justify-start">
          <div className="rounded-md shadow">
            <Link to="contact"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-700 hover:bg-purple-800 md:py-4 md:text-lg md:px-10">
              Get started
            </Link>
          </div>
        </div>
      </div>
      <div className="order-first md:order-last md:w-2/5 sm:pr-4">
        <img className="w-2/3 mx-auto md:w-full" src={heroImage} alt="" />
      </div>
    </main>

    <section className="pt-8">
      <div className="max-w-xl mx-auto px-4 sm:px-6 md:max-w-7xl md:px-8">
        <h2 className="sr-only">Integrated payments.</h2>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <div className="flex items-center justify-center h-12 w-12">
              <svg className="h-12 w-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" aria-hidden="true">
                <g className="fa-group">
                  <path className="text-purple-300" fill="currentColor"
                        d="M144 448H64v-80a16 16 0 0 0-16-16H16a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-448H16A16 16 0 0 0 0 16v128a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h80a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16zm480 0H496a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h80v80a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16zm0 352h-32a16 16 0 0 0-16 16v80h-80a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V368a16 16 0 0 0-16-16z" />
                  <path className="text-purple-700" fill="currentColor"
                        d="M166.5,232.4h131.9V100.5H166.5V232.4z M188.5,122.5h87.9v87.9h-87.9V122.5z M342.4,100.5v131.9h131.9V100.5H342.4z M452.3,210.4h-87.9v-87.9h87.9V210.4z M166.5,408.3h131.9V276.4H166.5V408.3z M188.5,298.4h87.9v87.9h-87.9V298.4z M210.5,144.5h44 v44h-44V144.5z M430.3,188.4h-44v-44h44V188.4z M210.5,320.4h44v44h-44V320.4z M452.3,276.4h22v87.9h-87.9v-22h-22v66h-22V276.4h66 v22h44V276.4z M452.3,386.3h22v22h-22V386.3z M408.4,386.3h22v22h-22V386.3z" />
                </g>
              </svg>
            </div>
            <div className="mt-5">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Scan and Pay
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                SmartPay 21 generates a unique code for every bill. Print the code on a statement, or display it
                anywhere! Customers scan the code to make a payment.
              </dd>
            </div>
          </div>

          <div>
            <div className="flex items-center justify-center h-12 w-12">
              <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-hidden="true">
                <g className="fa-group">
                  <path className="text-purple-300" fill="currentColor"
                        d="M448 0H64A64.06 64.06 0 0 0 0 64v288a64.06 64.06 0 0 0 64 64h96v84a12 12 0 0 0 19.1 9.7L304 416h144a64.06 64.06 0 0 0 64-64V64a64.06 64.06 0 0 0-64-64zM288 264a8 8 0 0 1-8 8H136a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8zm96-96a8 8 0 0 1-8 8H136a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h240a8 8 0 0 1 8 8z" />
                  <path className="text-purple-700" fill="currentColor"
                        d="M280 240H136a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h144a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8zm96-96H136a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h240a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8z" />
                </g>
              </svg>
            </div>
            <div className="mt-5">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Text and Pay
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Use the notification feature in SmartPay 21 to send a text. Customers can click a link in the text to
                complete the payment.
              </dd>
            </div>
          </div>

          <div>
            <div className="flex items-center justify-center h-12 w-12">
              <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-hidden="true">
                <g className="fa-group">
                  <path className="text-purple-300" fill="currentColor"
                        d="M128 272v48h360a24 24 0 0 1 24 24v16a24 24 0 0 1-24 24H128v48c0 21.44-25.94 32-41 17L7 369a24 24 0 0 1 0-33.94l80-80c15.14-15.12 41-4.35 41 16.94z" />
                  <path className="text-purple-700" fill="currentColor"
                        d="M505 143.05a24 24 0 0 1 0 33.95l-80 80c-15 15-41 4.49-41-17v-48H24a24 24 0 0 1-24-24v-16a24 24 0 0 1 24-24h360V80c0-21.36 25.9-32 41-17z" />
                </g>
              </svg>
            </div>
            <div className="mt-5">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Frictionless payments
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                No downloads to make a payment! Scanning a code, or clicking a link will open the mobile payment app in
                the mobile browser.
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </section>

    <section className="relative h-24 lg:h-36 xl:h-48">
      <svg className="absolute bottom-0 -mb-1 left-0 w-full" viewBox="0 0 2880 310" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path className="text-gray-50" fillRule="evenodd" clipRule="evenodd" fill="currentColor"
              d="M0,306.78q713.64-4,1420-77.89A14432.37,14432.37,0,0,0,2880,0V306.78Z" />
      </svg>
    </section>

    <section id="retail" className="bg-gray-50 py-4">
      <div className="flex max-w-xl mx-auto px-4 sm:px-6 md:max-w-6xl md:px-8">
        <div className="flex flex-col md:flex-row w-full md:items-center">
          <div className="md:w-3/5 md:pr-4">
            <h3 className="text-2xl text-gray-700 tracking-tight lg:text-3xl">
              Retail
            </h3>
            <p className="mt-3 lg:text-lg text-gray-500">
              Move customers through retail lanes faster with SmartPay 21. Our POI web app instantly displays a unique
              pay code at checkout. Customers can complete the payment with a single click without downloading an app on
              their smartphone. SmartPay 21 is the fast and secure way to pay in retail.
            </p>
          </div>
          <div className="order-first md:order-last w-2/3 pr-10 md:w-2/5 mx-auto py-6">
            <img src={retailImage} alt="" />
          </div>
        </div>
      </div>
    </section>

    <section id="curbside" className="bg-gray-50 py-4">
      <div className="flex max-w-xl mx-auto px-4 sm:px-6 md:max-w-7xl md:px-8 lg:justify-end">
        <div className="flex flex-col md:flex-row w-full md:items-center">
          <div className="md:w-2/3 lg:w-3/5 md:pl-10">
            <h3 className="text-2xl text-gray-700 tracking-tight lg:text-3xl">
              Curbside
            </h3>
            <p className="mt-3 lg:text-lg text-gray-500">
              Take curbside payments as easily as in-store retail. The POI web app works on any mobile device and
              delivers a unique pay code for checkout. Customers only need line-of-site access to make a payment,
              so they can stay safely inside their vehicle with the windows up.
            </p>
          </div>
          <div className="order-first w-2/3 md:w-1/3 lg:w-2/5 mx-auto py-6">
            <img src={curbsideImage} alt="" />
          </div>
        </div>
      </div>
    </section>

    <section id="moto" className="bg-gray-50 py-10">
      <div className="flex max-w-xl mx-auto px-4 sm:px-6 md:max-w-6xl md:px-8">
        <div className="flex flex-col md:flex-row w-full md:items-center">
          <div className="md:w-4/5 md:pr-4 lg:ml-24">
            <h3 className="text-2xl text-gray-700 tracking-tight lg:text-3xl">
              Ordering and Billing Services
            </h3>
            <p className="mt-3 lg:text-lg text-gray-500">
              SmartPay 21 works perfectly with mail, phone, and billing services. Use it to approve the amount, then
              capture it later when the order is fulfilled. SmartPay 21 supports multiple notification methods, making
              it easy to pay by SMS, email, or statement.
            </p>
          </div>
          <div className="order-first md:order-last w-1/3 md:w-1/5 mx-auto py-6 md:pr-4">
            <img src={motoImage} alt="" />
          </div>
        </div>
      </div>
    </section>

    <section id="field" className="bg-gray-50 py-4">
      <div className="flex max-w-xl mx-auto px-4 sm:px-6 md:max-w-7xl md:px-8 lg:justify-center">
        <div className="flex flex-col md:flex-row w-full lg:w-4/5 md:items-center">
          <div className="md:w-4/5 md:pl-10">
            <h3 className="text-2xl text-gray-700 tracking-tight lg:text-3xl">
              Field Services
            </h3>
            <p className="mt-3 lg:text-lg text-gray-500">
              Take SmartPay 21 anywhere! The POI web app can be used in the field to display the unique pay code for
              payment. Customers simply point their camera and click a button to complete payment. The service agent
              and customer are instantly updated when the bill is paid.
            </p>
          </div>
          <div className="order-first w-1/2 md:w-1/5 lg:w-1/3 mx-auto py-6">
            <img src={fieldServiceImage} alt="" />
          </div>
        </div>
      </div>
    </section>

    <section className="relative h-24 lg:h-36 xl:h-48">
      <svg className="absolute top-0 -mt-1 left-0 w-full" viewBox="0 0 2880 250" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path className="text-gray-50" fillRule="evenodd" clipRule="evenodd" fill="currentColor"
              d="M0,250.7C961.86-4.57,2509.06,10.52,2880,10.7V0H0Z" />
      </svg>
    </section>

    <section className="relative overflow-hidden pt-6 pb-16 overflow-hidden lg:pb-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 md:max-w-7xl">
        <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl text-gray-900 tracking-tight lg:text-3xl">
              SmartPay 21 Web Apps
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              The two web applications included with SmartPay 21 will display the unique QR code, and take payment from
              the cardholder. There are no apps to download and install for the point-of-interaction (POI) or on the
              cardholder's mobile device.
            </p>
            <div className="relative lg:hidden w-4/5 mt-6 mx-auto">
              <img
                className="absolute inset-y-0 right-0 w-24 md:w-48 mt-2 rounded-lg border border-gray-200 shadow-xl z-10"
                src={mobileScreenShot} alt="" />
              <img className="relative mx-auto" src={posScreenShot} alt="" />
            </div>
            <dl className="mt-10 space-y-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-10 w-10">
                    <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
                         aria-hidden="true">
                      <g className="fa-group">
                        <path className="text-purple-300" fill="currentColor"
                              d="M192 0C86 0 0 86 0 192c0 77.41 27 99 172.27 309.67a24 24 0 0 0 39.46 0C357 291 384 269.41 384 192 384 86 298 0 192 0zm0 288a96 96 0 1 1 96-96 96 96 0 0 1-96 96z" />
                        <path className="text-purple-700" fill="currentColor"
                              d="M192 256a64 64 0 1 1 64-64 64 64 0 0 1-64 64z" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Point of Interaction (POI)
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Replace or include the POI application alongside legacy payment terminals. SmartPay 21 will
                    instantly display a unique QR code when the customer is ready for checkout.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-10 w-10">
                    <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                         aria-hidden="true">
                      <g className="fa-group">
                        <path className="text-purple-300" fill="currentColor"
                              d="M0 384V48A48 48 0 0 1 48 0h224a48 48 0 0 1 48 48v336z" />
                        <path className="text-purple-700" fill="currentColor"
                              d="M0 384v80a48 48 0 0 0 48 48h224a48 48 0 0 0 48-48v-80zm160 96a32 32 0 1 1 32-32 32 32 0 0 1-32 32z" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Mobile Payment App
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Scanning the unique QR code launches the payment app in the mobile web browser. Cardholders complete
                    the transaction using their mobile wallet, or by entering their payment information.
                  </dd>
                </div>
              </div>
            </dl>
          </div>

          <div className="hidden lg:block">
            <div className="img-perspective img-perspective-left ">
              <img className="relative mx-auto img-perspective-item shadow-2xl" src={posScreenShot} alt="" />
            </div>
            <img
              className="absolute bottom-0 mb-16 xl:mb-0 right-0 -m-4 w-32 xl:w-44 border border-gray-300 rounded-xl shadow-xl"
              src={mobileScreenShot} alt="" />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="bg-purple-700">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-light text-white sm:text-4xl">
            <span className="block">Deliver modern contactless payments.</span>
            <span className="block">Integrate SmartPay 21.</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-purple-200">The SmartPay 21 API is the easiest way to add modern,
            secure, contactless payments to your POS!</p>
          <Link to="contact"
             className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-purple-600 bg-white hover:bg-purple-50 sm:w-auto">
            Schedule a demo
          </Link>
        </div>
      </div>
    </section>


  </Layout>
)

export default IndexPage
